// 本地权限列表
let env = {
    // ICP号
    ICP: '沪ICP备20009731号',
    // 开发模式-默认否
    DE_BUG: process.env.VUE_APP_DE_BUG ?? 'false',
    // 接口地址
    API_URL: process.env.VUE_APP_API_URL ?? 'https://api.gymmartsports.com',
    // 分页条数
    pageSize: 10,
    //label默认宽度
    label_width: '120px',
    search_label_width: '100px',
    // message默认提示时间毫秒
    message_duration: 1000,
    message_error: 2000,
}
export default env
