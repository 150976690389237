import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import NotFound from '../views/NotFound.vue'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
    { path: '/', name: 'Login', component: Login },
    //注册
    { path: '/registered', name: '注册', component: () => import('@/views/Register') },
    {
        path: '', name: 'Admin', component: () => import('@/layouts/AdminBasics'),
        children: [
            /*************主页基础************/
            { path: '/home', name: 'home', component: () => import('@/views/home/Home') },             // 主页
            { path: '/home/password', name: '修改密码', component: () => import('@/views/home/Password') },
            /*************主页基础 end************/
            /*************系统设置****************/
            // 角色
            { path: '/systemset/role', name: '角色管理', component: () => import('@/views/systemset/role/List') },
            { path: '/systemset/role/see', name: '角色详情', component: () => import('@/views/systemset/role/Details') },
            // 用户
            { path: '/systemset/user', name: '系统用户', component: () => import('@/views/systemset/user/List') },
            { path: '/systemset/user/see', name: '用户详情', component: () => import('@/views/systemset/user/Details') },
            // 日志
            { path: '/systemset/log', name: '操作日志', component: () => import('@/views/systemset/log/List') },
            /*************系统设置 END************/
            /*************门店管理************/
            //门店管理
            { path: '/store/store', name: '门店列表', component: () => import('@/views/store/store/List') },
            { path: '/store/store/add', name: '门店添加', component: () => import('@/views/store/store/Add') },
            { path: '/store/store/details', name: '门店详情', component: () => import('@/views/store/store/Details') },
            { path: '/store/store/info', name: '门店详情', component: () => import('@/views/store/store/page/Info') },
            { path: '/store/store/edit', name: '门店详情', component: () => import('@/views/store/store/page/Edit') },
            /*************门店管理 END************/
            /*************课程管理 ************/
            { path: '/lesson/lesson', name: '课程列表', component: () => import('@/views/lesson/lesson/List') },
            { path: '/lesson/lesson/info', name: '课程详情', component: () => import('@/views/lesson/lesson/page/Info') },
            { path: '/lesson/lesson/edit', name: '课程编辑', component: () => import('@/views/lesson/lesson/page/Edit') },
            /*************课程管理 end************/
            /*************产品管理************/
            { path: '/product/sportsticket', name: '运动票列表', component: () => import('@/views/product/sportsticket/List') },
            { path: '/product/sportsticket/add', name: '运动票添加', component: () => import('@/views/product/sportsticket/Add') },
            { path: '/product/sportsticket/details', name: '运动票详情', component: () => import('@/views/product/sportsticket/Details') },

            { path: '/product/activity', name: '活动列表', component: () => import('@/views/product/activity/List') },
            { path: '/product/activity/add', name: '活动添加', component: () => import('@/views/product/activity/Add') },
            { path: '/product/activity/details', name: '活动详情', component: () => import('@/views/product/activity/Details') },
            { path: '/product/activity/info', name: '活动详情', component: () => import('@/views/product/activity/page/Info') },
            // , meta: { keepAlive: true }
            // 商品管理
            { path: '/product/commodity', name: '商品', component: () => import('@/views/product/commodity/List') },
            { path: '/product/commodity/edit', name: '商品添加', component: () => import('@/views/product/commodity/page/Edit') },
            { path: '/product/commodity/info', name: '商品详情', component: () => import('@/views/product/commodity/page/Info') },
            { path: '/product/commodity/edit', name: '商品详情', component: () => import('@/views/product/commodity/page/Edit') },
            { path: '/product/commodityorder', name: '商品订单', component: () => import('@/views/product/commodityorder/List') },

            // 赛事管理
            { path: '/product/contest', name: '赛事列表', component: () => import('@/views/product/contest/List') },
            { path: '/product/contest/info', name: '赛事详情', component: () => import('@/views/product/contest/Info') },
            { path: '/product/contest/edit', name: '赛事详情', component: () => import('@/views/product/contest/Edit') },
            /*************产品管理 end************/
            /*************课表管理************/
            { path: '/lessonschedule/lessonfastarrange', name: '快速排课', component: () => import('@/views/lessonschedule/lessonarrange/Fastarrange') },
            { path: '/lessonschedule/groupteaching', name: '团课管理', component: () => import('@/views/lessonschedule/groupteaching/List') },
            { path: '/lessonschedule/groupteaching/edit', name: '团课编辑', component: () => import('@/views/lessonschedule/groupteaching/Edit') },
            { path: '/lessonschedule/groupteaching/info', name: '团课详情', component: () => import('@/views/lessonschedule/groupteaching/Info') },
            { path: '/lessonschedule/camp', name: '训练营管理', component: () => import('@/views/lessonschedule/camp/List') },
            { path: '/lessonschedule/camp/edit', name: '训练营编辑', component: () => import('@/views/lessonschedule/camp/Edit') },
            { path: '/lessonschedule/camp/info', name: '训练营编辑', component: () => import('@/views/lessonschedule/camp/Info') },
            { path: '/lessonschedule/pt', name: '私教管理', component: () => import('@/views/lessonschedule/pt/List') },
            { path: '/lessonschedule/pt/edit', name: '私教编辑', component: () => import('@/views/lessonschedule/pt/Edit') },
            { path: '/lessonschedule/pt/info', name: '私教编辑', component: () => import('@/views/lessonschedule/pt/Info') },
            { path: '/lessonschedule/ptsku', name: '私教规格', component: () => import('@/views/lessonschedule/ptsku/Info') },
            /*************课表管理 end************/
            /*************订单管理************/
            { path: '/order/group', name: '团课列订单', component: () => import('@/views/order/group/List') },
            { path: '/order/camp', name: '训练营订单', component: () => import('@/views/order/camp/List') },
            { path: '/order/pt', name: '私教订单', component: () => import('@/views/order/pt/List') },
            { path: '/order/activity', name: '活动订单', component: () => import('@/views/order/activity/List') },
            { path: '/order/contest', name: '私教订单', component: () => import('@/views/order/contest/List') },
            /*************订单管理 end************/
            /*************账本管理************/
            { path: '/accountbook/monthly', name: '账本管理', component: () => import('@/views/accountbook/Monthly') },
            { path: '/accountbook/check', name: '对账列表', component: () => import('@/views/accountbook/Check') },
            { path: '/accountbook/withdrawal', name: '提现列表', component: () => import('@/views/accountbook/Withdrawal') },
            /*************账本管理 end************/
            /*************教练管理************/
            { path: '/coach/joinapply', name: '待加入', component: () => import('@/views/coach/joinapply/List') },
            { path: '/coach/companycoach', name: '我的教练', component: () => import('@/views/coach/companycoach/List') },
            { path: '/coach/companycoach/Details', name: '教练详情', component: () => import('@/views/coach/companycoach/Details') },
            /*************教练管理 end************/
            //商户管理-+
            { path: '/company/info', name: '商户管理', component: () => import('@/views/systemset/company/Details') },
            /*************智慧营销************/
            { path: '/consumercard/store', name: '消费卡订单', component: () => import('@/views/consumercard/store/List') },
            { path: '/groupbuy/list', name: '拼团活动', component: () => import('@/views/groupbuy/List') },
            { path: '/groupbuy/list/edit', name: '添加拼团产品', component: () => import('@/views/groupbuy/Edit') },
            { path: '/groupbuy/initiatelist', name: '拼团记录', component: () => import('@/views/groupbuy/Initiatelist') },
            /*************智慧营销 end************/
            /*************品牌管理************/
            { path: '/brand/brand', name: '品牌列表', component: () => import('@/views/brand/brand/List') },
            { path: '/brand/brand/details', name: '品牌详情', component: () => import('@/views/brand/brand/Details') },
            /*************品牌管理 end************/
        ]
    },
    { path: '*', name: 'NotFound', component: NotFound }

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
