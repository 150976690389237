<template>
    <div id="app">
        <keep-alive>
            <router-view v-if="$route.meta.keepAlive" class="router-view" />
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive" class="router-view"></router-view>
    </div>
</template>
<script>
import "@/assets/app.css";

export default {
    name: 'App',
    created() {
        if (this.env.DE_BUG === 'true') {   // 判断版本
            this.debug()
        }
        this.init()
    },
    methods: {
        debug() {
            console.log('*****************开发测试版本*****************')
            console.log('当前配置', this.env)
            console.log('↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓程序开始↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓')
        },
        init() {
            console.log('入口文件，初始化')
        }
    }
}
</script>

<style>
#app {
    /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
}
</style>
